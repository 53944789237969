import ConfirmationModal from "../../common/ConfirmationModal";

export default class UserDetail {
  
  constructor() {
    this.confirmModal = new ConfirmationModal();
    this.bindEvents();
  }

  bindEvents() {
    var self = this;

    $("#delete_user_btn").unbind("click").click(function(e) {
        e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.users.show.delete_user.";
        var userId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "danger",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
                  self.delete(userId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }

    });
    $("#reset_subscription").unbind("click").click(function(e) {
        e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.users.show.reset_subscription.";
        var userId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "danger",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
                  self.reset_subscription(userId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }

    });
  }

  delete(id) {
    var ajaxData = {
        url: "/users/"+id,
        method: "DELETE"
    };

    $.ajax(ajaxData).done(function(json) {
      Turbolinks.visit("/users/");
    }).fail(function(err) {
      Turbolinks.visit();
    });
  };

    reset_subscription(userId) {
        var ajaxData = {
            url: "/users/"+userId+"/resset_subscriptions/",
            method: "DELETE"
        };

        $.ajax(ajaxData).done(function(json) {
            Turbolinks.visit("/users/"+userId);
        }).fail(function(err) {
            Turbolinks.visit();
        });
    }
}

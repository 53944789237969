import * as select2 from "select2";

export default class CategoryForm {
	
	constructor() {
		window.$("#category_display_feature_ids").select2({
			allowClear: true,
			placeholder: I18n.t("backoffice.categories.form.display_features_placeholder")
		});
	}
}
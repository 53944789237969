import ConfirmationModal from "../../common/ConfirmationModal";

export default class Dashboard {
  
  constructor() {
    this.confirmModal = new ConfirmationModal();

    this.bindEvents();
  }

  bindEvents() {
    var self = this;

    this.bindCopyEvents();
    
    setTimeout(function() {
      self.updateDashboard();
    }, 1000);

    setTimeout(function() {
      self.updateMobileTrackersDashboard();
    }, 1000);

    setTimeout(function() {
      self.updateMqttTrackerDashboard();
    }, 10000);

    $(".btn-simulate").unbind("click").click(function(e) {
      var btn = this;
      e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.dashboard.simulation_modal.";
        var trackId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "success",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
                  self.simulate_now(btn, trackId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }
    });

    $(".btn-destroy").unbind("click").click(function(e) {
      var btn = this;
      e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.dashboard.delete_track_modal.";
        var trackId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "danger",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
                  self.destroy_track(btn, trackId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }
    });

    $(".btn-destroy-image").unbind("click").click(function(e) {
      var btn = this;
      e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.dashboard.delete_image_modal.";
        var trackId = $(this).data("track-id");
        var imageId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "danger",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
        var scope = "backoffice.dashboard.delete_image_modal.";
                  self.destroy_image(btn, trackId, imageId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }
    });

    $(".btn-destroy-activity-comment").unbind("click").click(function(e) {
      var btn = this;
      e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.dashboard.delete_activity_comment_modal.";
        var trackId = $(this).data("track-id");
        var activityCommentId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "danger",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
        var scope = "backoffice.dashboard.delete_activity_comment_modal.";
                  self.destroy_activity_comment(btn, trackId, activityCommentId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }
    });

    $(".btn-destroy-scene").unbind("click").click(function(e) {
      var btn = this;
      e.preventDefault();
        var disabled = $(this).hasClass("disabled");
        var scope = "backoffice.dashboard.delete_track_modal.";
        var trackId = $(this).data("id");

        if(!disabled) {
            self.confirmModal.displayConfirm(I18n.t(scope+"title"),
            I18n.t(scope+"message"),
            {
              positive_btn: {
                type: "danger",
                label: I18n.t(scope+"positive_btn"),
                callback: function() {
                  self.destroy_scene(btn, trackId);
                }
              },
              negative_btn: {
                label: I18n.t(scope+"negative_btn")
              }
          });
        }
    });
  }

  copyToClipboard(topic) {
    navigator.clipboard.writeText(topic).then(() => {
      console.log("Topic=" + topic);
    }).catch(err => {
      console.error("Can't copy :", err);
    });
  }

  bindCopyEvents() {
    var self = this;
    $('td[id^="topic-"]').click(function() {
      var cell = $(this);
      var topic = cell.text();
      self.copyToClipboard(topic);
      cell.removeClass('highlight-row');
      void cell[0].offsetWidth; 
      cell.addClass('highlight-row');
    });
  }

  updateDashboard() {
    if(window.application.controller_name=="dashboard" && window.application.action_name=="index") {
      var self = this;
      var ajaxData = {
          url: "/supervisor/live_tracks",
          method: "GET",
      };

      $.ajax(ajaxData).done(function(_html_) {

        $("#activity_items").html(_html_);
        setTimeout(function() {
          self.updateDashboard();
        }, 1000);
      }).fail(function(err) {
        
      });
    }
  }

  updateMqttTrackerDashboard() {
    if(window.application.controller_name=="dashboard" && window.application.action_name=="mqtt_manager") {
      var self = this;

      var urlParams = new URLSearchParams(window.location.search);
      var sortParam = urlParams.get('sort');

      var ajaxData = {
          url: "/supervisor/live_mqtt_manager",
          method: "GET",
          data: { sort: sortParam }
      };

      $.ajax(ajaxData).done(function(_html_) {

        $("#topic_items").html(_html_);
        // Allow once again to copy 
        self.bindCopyEvents();

        setTimeout(function() {
          self.updateMqttTrackerDashboard();
        }, 10000);
      }).fail(function(err) {
        
      });
    }
  }

  updateMobileTrackersDashboard() {
    if(window.application.controller_name=="dashboard" && window.application.action_name=="trackers") {
      var self = this;
      var ajaxData = {
          url: "/supervisor/live_trackers",
          method: "GET"
      };

      $.ajax(ajaxData).done(function(_html_) {

        $("#activity_items").html(_html_);
        setTimeout(function() {
          self.updateMobileTrackersDashboard();
        }, 1000);
      }).fail(function(err) {
        
      });
    }
  }


  simulate_now(btn, id) {
    $(btn).prop("disabled", true)
    var ajaxData = {
        url: "/supervisor/tracks/simulate/"+id,
        method: "PUT"
    };

    $.ajax(ajaxData).done(function(json) {
      
    }).fail(function(err) {
  
    });
  };

  destroy_track(btn, id) {
    $(btn).prop("disabled", true);
    var ajaxData = {
        url: "/supervisor/tracks/"+id,
        method: "DELETE"
    };

    $.ajax(ajaxData).done(function(json) {
      $("#activity-item-"+id).remove();
      $("#track-item-"+id).remove();
    }).fail(function(err) {
  
    });
  };

  destroy_image(btn, track_id, image_id) {
    $(btn).prop("disabled", true);
    var ajaxData = {
        url: "/supervisor/tracks/"+track_id+"/images/"+image_id,
        method: "DELETE"
    };

    $.ajax(ajaxData).done(function(json) {
      $("#image-item-"+image_id).remove();
    }).fail(function(err) {
  
    });
  };

  destroy_activity_comment(btn, track_id, activity_comment_id) {
    $(btn).prop("disabled", true);
    var ajaxData = {
        url: "/supervisor/tracks/"+track_id+"/activity_comments/"+activity_comment_id,
        method: "DELETE"
    };

    $.ajax(ajaxData).done(function(json) {
      $("#activity-comment-item-"+activity_comment_id).remove();
    }).fail(function(err) {
  
    });
  };

    destroy_scene(btn, id) {
    $(btn).prop("disabled", true);
    var ajaxData = {
        url: "/supervisor/scenes/"+id,
        method: "DELETE"
    };

    $.ajax(ajaxData).done(function(json) {
      //$("#activity-item-"+id).remove();
      $("#scene-item-"+id).remove();
    }).fail(function(err) {
  
    });
  };

}
import CategoryForm from "./controllers/CategoryForm"
import UserDetail from "./controllers/UserDetail"
import Dashboard from "./controllers/Dashboard"
import AnnonceForm from "./controllers/AnnonceForm";
import LocalityForm from "./controllers/LocalityForm";


export default class Application {
	
	constructor() {
		this.controller_name = null;
		this.action_name = null;

		this.current_handler = null;
		this._dataChangedListener = null;

		this.deferredPrompt = null;

		this.bindEvents();

    }

    bindEvents() {
      var self = this;

      $(document).off("turbolinks:before-visit").on('turbolinks:before-visit', function(e) {
        $(document).off("Application::PageChanged");
      });
    }

    setController(controller) {
      this.controller_name = controller;
    }

    setAction(action) {
      this.action_name = action;
    }

    setData(data) {
      this.data = data;
      if(this._dataChangedListener!=null) {
        this._dataChangedListener.onDataChanged(data);
      }
    }

    setOnDataChangeListener(listener) {
      this._dataChangedListener = listener;
    }

    pageChanged() {
		this.current_handler = null;
		this._dataChangedListener = null;

    if(this.controller_name=="dashboard") {
      this.current_handler = new Dashboard();
    }
		else if(this.controller_name=="categories") {
			if(this.action_name=="new" || this.action_name=="edit" || this.action_name=="create" ||this.action_name=="update") {
				this.current_handler = new CategoryForm();
			}
    }
    else if(this.controller_name=="users") {
      if(this.action_name=="show" || this.action_name=="update") {
        this.current_handler = new UserDetail();
      }
      else if(this.action_name==="show_tracks") {
        this.current_handler = new Dashboard();
      }
      else if(this.action_name=="show_scenes") {
        this.current_handler = new Dashboard();
      }
    }
  	else if(this.controller_name=="annonces") {
  		if(this.action_name=="edit" || this.action_name=="new") {
  			this.current_handler = new AnnonceForm();
  		}
  	}
    else if(this.controller_name=="localities") {
      if(this.action_name=="new" || this.action_name=="edit" || this.action_name=="create" ||this.action_name=="update") {
        this.current_handler = new LocalityForm();
      }
    }

    //$(document).trigger("Application::PageChanged", {});
    this.setData(window.data);
  }
}

$(document).on('turbolinks:load', function(event) {

	var url = event.currentTarget.URL;

	$(document).off("submit", "form[method=get]").on("submit", "form[method=get]", function(e) {
	    Turbolinks.visit(this.action+(this.action.indexOf('?') == -1 ? '?' : '&')+$(this).serialize());
      e.preventDefault();
	    return false;
	});

	if(window.application==null) {
	  window.application = new Application();
	}

	moment.locale(I18n.locale);

	Turbolinks.setProgressBarDelay(500);

	application.setController($("body").data("controller"));
	application.setAction($("body").data("action"));
	application.pageChanged();

	//$('[data-toggle="tooltip"]').tooltip();

});